// import SaveNewWord from "./SaveNewWord/SaveNewWord";
import About from './About/About'

function Home() {
  return (
    <main>
      <div className="wrapper">
        {/* <SaveNewWord /> */}
        <About />
      </div>
    </main>
  )
}

export default Home
